<template>
  <div class="privacy--policy-wrapper">
    <banner :banner-text="bannerText" />
    <div class="row">
      <div class="col-12 col-md-6">
        <div class="opening" v-html="$t('privacy.opening')"></div>
      </div>
      <div class="col-12 col-md-6 text-center d-flex justify-content-center main--image-wrapper">
        <img
          class="main--image"
          width="100%"
          src="https://rentfix.s3-ap-southeast-1.amazonaws.com/static/img/static/photo-home-owner-auckland.jpg"
        />
      </div>
    </div>
    <div class="intro--wrapper">
      <div class="introduction" v-html="$t('privacy.contentIntro')"></div>
    </div>
    <div class="content--wrapper">
      <informasi-pengguna></informasi-pengguna>
      <penggunaan-informasi></penggunaan-informasi>
      <cookies></cookies>
      <merk-dagang-beliruma></merk-dagang-beliruma>
    </div>
  </div>
</template>

<script>
import Banner from '@/components/utils/page-banner';
import InformasiPengguna from '@/components/static-page/privacy-policy/informasi-pengguna';
import PenggunaanInformasi from '@/components/static-page/privacy-policy/penggunaan-informasi';
import Cookies from '@/components/static-page/privacy-policy/cookies';
import MerkDagangBeliruma from '@/components/static-page/privacy-policy/merk-dagang-beliruma';
export default {
  name: 'privacy-policy',
  components: {
    Banner,
    InformasiPengguna,
    PenggunaanInformasi,
    Cookies,
    MerkDagangBeliruma,
  },
  computed: {
    bannerText() {
      return this.$t('privacy.title');
    },
  },
};
</script>
